import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(26.000000, 34.000000)">
        <path
          d="M19.06,26.47v4.53H0V0H19.06V4.53H5.2V13.17h13.06v4.23H5.2v9.07h13.86Zm18.15,4.53h-11.09V0h10.87c2.94,0,5.24,.68,6.9,2.04,1.66,1.36,2.49,3.24,2.49,5.65,0,1.69-.52,3.17-1.55,4.43-1.03,1.26-2.34,2.02-3.93,2.28v.34c2.06,.17,3.74,.96,5.04,2.35,1.3,1.4,1.94,3.11,1.94,5.15,0,2.72-.95,4.86-2.85,6.42-1.9,1.56-4.51,2.34-7.83,2.34ZM31.24,4.1V13.02h4.04c1.89,0,3.34-.38,4.36-1.15,1.02-.77,1.53-1.85,1.53-3.25s-.48-2.56-1.43-3.34c-.95-.78-2.31-1.17-4.07-1.17h-4.43Zm0,22.73h4.51c4.5,0,6.75-1.67,6.75-5.01s-2.28-4.9-6.83-4.9h-4.43v9.9Z"
          //d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
          fill="currentColor"
        />
      </g>
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
